import { chain, isString, pairs, sortBy, without, zip } from 'lodash';
import moment from 'moment';
import semverDiff from 'semver/functions/diff';

import semVerEnum from '../enums/semVerEnum';

import * as settings from '../settings';

/* Converts qualifiers defined on the backend to queries supported by the web client */
export const ecomQualifierToQuery = (qualifier) => ({
  selectedBaseTypeId: qualifier.baseTypeId,
  selectedSectionIds: qualifier.cyoSectionId != null ? [qualifier.cyoSectionId] : undefined,
  selectedCategoryIds:
    qualifier.ingredientCategoryId != null ? [qualifier.ingredientCategoryId] : undefined,
  selectedIngredientIds: qualifier.ingredientId != null ? [qualifier.ingredientId] : undefined,
});

export const mergeObjects = (array, func) =>
  chain(array)
    .map((obj) => sortBy(pairs(obj), ([key]) => key))
    .reduce((pairsA, pairsB) =>
      zip(pairsA, pairsB).map(([[key, valueA], [, valueB]]) => [key, func(valueA, valueB, key)]),
    )
    .fromPairs()
    .value();

const toCamelCaseString = (value) => {
  if (!isString(value)) {
    return value;
  }

  return value.replace(/-([a-z])/g, (match) => match[1].toUpperCase());
};

/* Clean delivery address from undefined and null values */
const cleanDeliveryAddress = (addressArray, excludeEmptyString = false) =>
  without([...addressArray], null, undefined, excludeEmptyString ? '' : undefined).join(', ');

/* Parse delivery address and generate a legible label for displays. */
export const parseDeliveryAddress = (deliveryAddress) => {
  if (!deliveryAddress) {
    return null;
  }

  const address = deliveryAddress.address.replace(
    `, ${settings.COUNTRY_NAME}, ${settings.COUNTRY_CODE_ALPHA3}`,
    '',
  );

  const deliveryAddressData = {
    address,
    postcode: deliveryAddress.postcode,
    floorUnit: deliveryAddress.floorUnit,
    buildingName: deliveryAddress.building,
    deliveryInstructions: deliveryAddress.deliveryInstructions,
    streetName: deliveryAddress.streetName,
  };

  const name = cleanDeliveryAddress([
    deliveryAddressData.floorUnit,
    deliveryAddressData.buildingName,
  ]);

  const label = cleanDeliveryAddress(
    [
      deliveryAddressData.floorUnit,
      cleanDeliveryAddress([
        [
          deliveryAddressData.buildingName,
          deliveryAddressData.streetName,
          deliveryAddressData.postcode,
        ],
      ]),
    ],
    true,
  );

  return {
    ...deliveryAddressData,
    name,
    label,
  };
};

/* Group surcharges based on surchargeType */
export const groupSurcharges = (surcharges) =>
  surcharges.reduce((allSurcharges, surcharge) => {
    const surchargeKey = (() => {
      const surchargeType = toCamelCaseString(surcharge.surchargeType);

      if (surchargeType === 'orderTotalEnf') {
        return 'enforceMinPrice';
      }

      return surchargeType;
    })();

    return {
      ...allSurcharges,
      [surchargeKey]: {
        description: surcharge.description,
        pretaxPrice: surcharge.pretaxPrice,
        price: surcharge.price,
        discountAmount: surcharge.discountAmount,
        totalTax: surcharge.totalTax,
      },
    };
  }, {});

export const getMenuItemGglocationMenus = ({ apiId, menuGroups }) =>
  Object.keys(menuGroups).filter((menuGroupId) =>
    menuGroups[menuGroupId].some((menuItemId) => menuItemId === `${apiId}_${menuGroupId}`),
  );

/* A function to add divisors to a value */
export const addThousandsDivider = ({ value, divider }) => {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, divider);
  return parts.join('.');
};

export const getIsDiningChoiceDisabled = ({
  diningChoiceOption,
  isLandingPageOrder,
  landingPageDisabledDiningChoices,
}) =>
  !diningChoiceOption ||
  (isLandingPageOrder && landingPageDisabledDiningChoices?.includes(diningChoiceOption.diningType));

export const getUrlFromString = (url) => {
  try {
    return new URL(url);
  } catch (_) {
    return undefined;
  }
};

/**
 * A JS variant of python zip function.
 * Creates an array of grouped elements, the first of which contains the
 * first elements of the given arrays, the second of which contains the
 * second elements of the given arrays, and so on.
 *
 * Noted that it only follow the shortest length of the arrays, as in python,
 * others will be discarded, which is different from lodash and underscore
 *
 * @param {...Array} [arrays] The arrays to process.
 * @returns {Array} Returns the new array of grouped elements.
 * @see zip (from python)
 * @example
 *
 * zip(['a', 'b', 'c'], [1, 2, 4], [true, false, true])
 * // => [['a', 1, true], ['b', 2, false], ['c', 4, true]]
 */
export const pyZip = (...arrays) =>
  Array(Math.min(...arrays.map((arr) => arr.length)))
    .fill()
    .map((_, i) => arrays.map((a) => a[i]));

export const sumObjectValues = (obj) => Object.values(obj).reduce((acc, val) => acc + val, 0);

/**
 * Remove the brand tag in version string.
 * As in production, version will padded with brand,
 * which might mess with semver detection.
 *
 * @param {string} versionString version string
 * @returns Filtered version string
 */
const cleanBrandFlag = (versionString) => {
  const versionArray = versionString.split('-');

  if (versionArray.length > 1) {
    // Only include if from actual prereleases
    if (!/^(alpha|beta|staging|dev)/i.test(versionArray[1])) {
      return versionArray[0];
    }
  }
  return versionString;
};

/**
 * Return semver diff in string.
 * String returns include:
 * @enum
 * - `same`|`major`|`minor`|`patch`|`premajor`|`preminor`|`prepatch`|`prerelease`
 *
 * For more details, please refer: https://github.com/npm/node-semver/blob/v7.5.4/functions/diff.js
 *
 * @param {string} v1 First version to compare
 * @param {string} v2 Second version to compare
 * @returns {string} Semver diff
 */
export const compareSemVerDiff = (v1, v2) => {
  if (v1 === v2) return semVerEnum.SAME;
  const diff = semverDiff(cleanBrandFlag(v1), cleanBrandFlag(v2));
  if (diff !== null) return diff;
  return semVerEnum.SAME;
};

export const optionalMinuteMomentFormat = (momentObj, options = {}) => {
  let date = momentObj;
  if (!moment.isMoment(date)) {
    date = moment(momentObj);
  }
  if (!date.isValid()) return '';

  const prefixFormat = options?.prefixFormat ?? '';
  const suffixFormat = options?.suffixFormat ?? 'a';
  const hourFormat = options?.hourFormat ?? 'h';
  const minuteFormat = date.minute() !== 0 ? ':mm' : '';
  const timeFormat = `${prefixFormat}${hourFormat}${minuteFormat}${suffixFormat}`;

  return date.format(timeFormat);
};

/**
 * Check if the string only has digits
 * @param {string} value
 * @returns boolean
 */
export const hasOnlyDigits = (value) => /^-?\d+$/.test(value);
